import React, { FC } from 'react';

import styles from '@/common/styles/components/error-page.module.scss';

/**
 * 공통 에러페이지
 * @returns
 */
export const CommonErrorPage: FC = () => {
  return (
    <div className={styles.error_page}>
      <i className="icon"></i>
      <strong>앗, 오류가 발생했어요!</strong>
      <p>잠시 후 다시 시도해주세요</p>
      <button
        type="button"
        onClick={() => {
          window.location.reload();
        }}
      >
        새로고침
      </button>
    </div>
  );
};
