import React, { FC } from 'react';

import styles from '@/common/styles/components/loader.module.scss';
import loader from '@/common/assets/images/img_loading.gif';

/**
 * 공통 로딩 페이지
 * @returns
 */

export const CommonLoader: FC = () => {
  return (
    <div className={styles.loader_wrap}>
      <img src={loader.src} alt="로딩" />
    </div>
  );
};
