import React, { useState, useCallback, useEffect, FC } from 'react';

import { ScrollViewStatsWrapper } from '@/common/components/hoc';
import { CommonMiniContent } from './CommonMiniContent';
import { CommonWideContent } from './CommonWideContent';

import { TopicContent } from '@/common/domain';
import { contains, getChunksForRows, getHead } from '@/common/utils';
import { useInfiteScroll } from '@/common/hooks';
import {
  ITEM_PER_CONTENTS_PAGE,
  PAGE_GROUP_FOR_AD_1,
  PAGE_GROUP_FOR_AD_2,
  PAGE_NAMES,
  ROWS_PER_CONTETNS_PAGE,
} from '@/common/constants';

interface CommonContentsProps {
  contents: TopicContent[];
  pageName: typeof PAGE_NAMES[keyof typeof PAGE_NAMES];
}

export const CommonContents: FC<CommonContentsProps> = ({
  contents,
  pageName,
}) => {
  const maxPage = Math.floor(contents?.length / ITEM_PER_CONTENTS_PAGE);
  const [currentPage, setCurrentPage] = useState(0);
  const { notiferRef } = useInfiteScroll(setCurrentPage, currentPage);
  const [chunks, setChunks] = useState<TopicContent[][]>(
    getChunksForRows(
      contents.slice(0, (currentPage + 1) * ITEM_PER_CONTENTS_PAGE)
    )
  );

  useEffect(() => {
    if (!contents) return;

    setChunks(
      getChunksForRows(
        contents.slice(0, (currentPage + 1) * ITEM_PER_CONTENTS_PAGE)
      )
    );
  }, [currentPage, contents]);

  const renderFeedContents = useCallback(() => {
    return (
      <>
        {chunks?.map((chunk, chunkIndex) => {
          return (
            <ScrollViewStatsWrapper
              event="@ScrollView"
              scrollPos={`row${chunkIndex + 2}`}
              key={getHead(chunk).title}
              isOnScrollToBottom={
                (chunkIndex + 1) / ROWS_PER_CONTETNS_PAGE === maxPage
              }
            >
              {chunk.map((content, contentIndex) => {
                if (
                  chunkIndex % 2 &&
                  (!contentIndex || contentIndex === chunk.length - 1)
                )
                  return (
                    <CommonWideContent content={content} key={content.title} />
                  );

                return (
                  <CommonMiniContent content={content} key={content.title} />
                );
              })}
            </ScrollViewStatsWrapper>
          );
        })}
      </>
    );
  }, [chunks, maxPage]);

  const renderOddNumberCategoryContents = React.useCallback(() => {
    return (
      <>
        {chunks?.map((chunk, chunkIndex) => {
          return (
            <ScrollViewStatsWrapper
              event="@ScrollView"
              scrollPos={`row${chunkIndex + 1}`}
              key={getHead(chunk).title}
              isOnScrollToBottom={
                (chunkIndex + 1) / ROWS_PER_CONTETNS_PAGE === maxPage
              }
            >
              {chunk.map((content, contentIndex) => {
                if (
                  chunkIndex % 2 &&
                  (!contentIndex || contentIndex === chunk.length - 1)
                )
                  return (
                    <CommonWideContent content={content} key={content.title} />
                  );

                return (
                  <CommonMiniContent content={content} key={content.title} />
                );
              })}
            </ScrollViewStatsWrapper>
          );
        })}
      </>
    );
  }, [chunks, maxPage]);

  const renderEvenNumberCategoryContents = React.useCallback(() => {
    return (
      <>
        {chunks?.map((chunk, chunkIndex) => {
          return (
            <ScrollViewStatsWrapper
              event="@ScrollView"
              scrollPos={`row${chunkIndex + 1}`}
              key={getHead(chunk).title}
              isOnScrollToBottom={
                (chunkIndex + 1) / ROWS_PER_CONTETNS_PAGE === maxPage
              }
            >
              {chunk.map((content, contentIndex) => {
                if (
                  chunkIndex % 2 &&
                  (!contentIndex || contentIndex === chunk.length - 1)
                )
                  return (
                    <CommonWideContent content={content} key={content.title} />
                  );

                return (
                  <CommonMiniContent content={content} key={content.title} />
                );
              })}
            </ScrollViewStatsWrapper>
          );
        })}
      </>
    );
  }, [chunks, maxPage]);

  const renderContents = () => {
    if (contains(PAGE_GROUP_FOR_AD_1, pageName))
      return renderOddNumberCategoryContents();

    if (contains(PAGE_GROUP_FOR_AD_2, pageName))
      return renderEvenNumberCategoryContents();

    return renderFeedContents();
  };

  return (
    <>
      {renderContents()}
      {!(currentPage > maxPage) && <div ref={notiferRef} />}
    </>
  );
};
