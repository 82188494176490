import React, {
  useCallback,
  useState,
  FC,
  EventHandler,
  MouseEvent,
} from 'react';
import { useDispatch } from 'react-redux';
import * as clipboard from 'clipboard-polyfill';

import styles from '@/common/styles/components/news-item.module.scss';
import {
  CollectInterests,
  collectInterestsLabels,
  CollectTopicContent,
  addSaveContent,
  COLLECT_KEY,
  removeSaveContent,
  addHiddenMedia,
  addHiddenContent,
  addReadContent,
  fetchPersonalState,
} from '@/common/store/slices/personal';
import { useRootSelector } from '@/common/hooks';
import { toggleModal } from '@/common/store/slices/modal/modalSlice';
import { ClickStatsWrapper } from '@/common/components/hoc';
import { TV } from '@/common/constants';
import { makeThumb } from '@/common/utils';

interface Props {
  content: CollectTopicContent;
}

/**
 * 공통으로 사용하는 작은 컨텐츠 컴포넌트
 * @returns
 */
export const CommonMiniContent: FC<Props> = ({ content }) => {
  const dispatch = useDispatch();
  const isLogin = useRootSelector((state) => state.rootSlice.isLogin);
  const currentRoute = useRootSelector((state) => state.rootSlice.currentRoute);
  const saveContents = useRootSelector(
    (state) => state.personalSlice[COLLECT_KEY].saveContents
  );

  const [isMore, setIsMore] = useState(false);
  const isSaved = saveContents.some(
    (saveContent) => saveContent.link === content.link
  );

  const toggleSaveContent: EventHandler<MouseEvent> = useCallback(
    async (event) => {
      event.preventDefault();

      if (!isLogin) {
        dispatch(toggleModal({ modal: 'nonLoginUserModal' }));

        return;
      }

      if (isSaved) {
        await dispatch(removeSaveContent(content));
        await dispatch(fetchPersonalState());

        return;
      }

      await dispatch(addSaveContent(content));
      await dispatch(fetchPersonalState());
    },
    [content, isSaved, isLogin, dispatch]
  );

  const more = useCallback(() => {
    setIsMore((prev) => !prev);
  }, []);

  const exceptMedia: EventHandler<MouseEvent> = useCallback(
    async (event) => {
      event.preventDefault();

      if (isLogin) {
        await dispatch(
          addHiddenMedia({
            origin: content.origin,
            hiddenDate: new Date().toLocaleDateString('ko-KR'),
          })
        );
        await dispatch(fetchPersonalState());

        return;
      }

      dispatch(
        toggleModal({
          modal: 'nonLoginUserModal',
        })
      );

      more();
    },
    [content.origin, isLogin, dispatch, more]
  );

  const exceptContent = useCallback(async () => {
    if (isLogin) {
      await dispatch(addHiddenContent(content));
      await dispatch(fetchPersonalState());

      return;
    }

    dispatch(
      toggleModal({
        modal: 'nonLoginUserModal',
      })
    );
  }, [isLogin, content, dispatch]);

  const readContent = useCallback(async () => {
    if (isLogin) {
      await dispatch(addReadContent(content));
      await dispatch(fetchPersonalState());
    }
  }, [isLogin, content, dispatch]);

  const copyLink = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      const { link } = content;

      clipboard
        .writeText(link)
        .then(() => dispatch(toggleModal({ modal: 'shareModal' })));
    },
    [content, dispatch]
  );

  return (
    <>
      <section className={styles.news_single_item_small}>
        <ClickStatsWrapper
          event="@Click"
          properties={{
            pos: 'start_contents',
            selectTab: currentRoute,
            cm: 'item',
            outurl: content.link,
            thumb: makeThumb(content),
          }}
        >
          <a href={content.link} onClick={readContent} target="_blank">
            <div className="thumb">
              {content.thumbnail ? (
                <img
                  src={content.smallThumbnail || content.thumbnail}
                  alt={content.title}
                  loading="lazy"
                />
              ) : (
                <span className="img_default" />
              )}
            </div>
            <ul className="info">
              <li>
                {content.category === 'tv'
                  ? 'TV'
                  : { ...collectInterestsLabels }[
                      content.subCategory as keyof CollectInterests
                    ] ||
                    { ...collectInterestsLabels }[
                      content.category as keyof CollectInterests
                    ]}
              </li>
              <li>{content.origin}</li>
            </ul>
            <div className="title">{content.title}</div>
          </a>
        </ClickStatsWrapper>

        <div className={`${styles.item_btns} ${styles.item_bottom}`}>
          {isLogin ? (
            <ClickStatsWrapper
              event="@Click"
              properties={{
                pos: 'start_contents',
                selectTab: currentRoute,
                cm: isSaved ? 'save_off' : 'save_on',
                outurl: content.link,
                thumb: makeThumb(content),
              }}
            >
              <button
                type="button"
                className={isSaved ? 'btn_bookmark clicked' : 'btn_bookmark'}
                onClick={toggleSaveContent}
              >
                북마크
              </button>
            </ClickStatsWrapper>
          ) : (
            <button
              type="button"
              className={isSaved ? 'btn_bookmark clicked' : 'btn_bookmark'}
              onClick={toggleSaveContent}
            >
              북마크
            </button>
          )}

          <div className={isMore ? 'edit clicked' : 'edit'}>
            <ClickStatsWrapper
              event="@Click"
              properties={{
                pos: 'start_contents',
                selectTab: currentRoute,
                cm: 'more',
                outurl: content.link,
                thumb: makeThumb(content),
              }}
            >
              <button type="button" onClick={more}>
                더보기
              </button>
            </ClickStatsWrapper>

            {isMore && (
              <ul className="edit_layer">
                <li>
                  {isLogin ? (
                    <ClickStatsWrapper
                      event="@Click"
                      properties={{
                        pos: 'start_contents',
                        selectTab: currentRoute,
                        cm: 'more_share',
                        outurl: content.link,
                        thumb: makeThumb(content),
                      }}
                    >
                      <a href="#" onClick={copyLink}>
                        <i className="icon_share" />
                        공유하기
                      </a>
                    </ClickStatsWrapper>
                  ) : (
                    <a href="#" onClick={copyLink}>
                      <i className="icon_share" />
                      공유하기
                    </a>
                  )}
                </li>
                <li>
                  {isLogin ? (
                    <ClickStatsWrapper
                      event="@Click"
                      properties={{
                        pos: 'start_contents',
                        selectTab: currentRoute,
                        cm: 'more_hiding',
                        outurl: content.link,
                        thumb: makeThumb(content),
                      }}
                    >
                      {content.category !== TV && (
                        <a href="#" onClick={exceptMedia}>
                          <i className="icon_block" />
                          매체사 제외하기
                        </a>
                      )}
                    </ClickStatsWrapper>
                  ) : (
                    content.category !== TV && (
                      <a href="#" onClick={exceptMedia}>
                        <i className="icon_block" />
                        매체사 제외하기
                      </a>
                    )
                  )}
                </li>
              </ul>
            )}
          </div>
        </div>

        {isLogin ? (
          <ClickStatsWrapper
            event="@Click"
            properties={{
              pos: 'start_contents',
              selectTab: currentRoute,
              cm: 'delete',
              outurl: content.link,
              thumb: makeThumb(content),
            }}
          >
            <button
              type="button"
              className={styles.btn_item_remove}
              onClick={exceptContent}
            >
              삭제
            </button>
          </ClickStatsWrapper>
        ) : (
          <button
            type="button"
            className={styles.btn_item_remove}
            onClick={exceptContent}
          >
            삭제
          </button>
        )}
      </section>
      {isMore && <div className={styles.edit_bg} onClick={more}></div>}
    </>
  );
};
